import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import FoodPage from '../../components/location-specific/FoodPage'

class FoodBryant extends React.Component
{
	render()
	{
		const location = "bryant";
		const currentPage = "food";
		const title = "Food & Pastries";

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<FoodPage location={location} />
			</Layout>
		)
	}
}

export default FoodBryant